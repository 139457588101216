import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export default function Register() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const newsletterRef = useRef();

  const [errorMessage, setErrorMessage] = useState(undefined);

  // after backend registration success use this state to redirect to /login form
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleSubmit = async (data) => {
    data.preventDefault();
    console.log("Register form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      password_confirm: passwordConfirmRef.current.value,
      newsletter: newsletterRef.current.checked
    }
    console.log("Register API query body: ", body);
    // send API request to register back-end
    axios.post("/api/user/register", body)
      .then(res => {
        console.log("Register API response: ", res.data)
        setShouldRedirect(true);
      })
      .catch(err => {
        console.log("Registration failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
      });
  }

  return (
    <Container className='d-flex align-items-center justify-content-center' style={{ minheight: "100vh" }}>

      <Helmet>
        <title>Register - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>

      <div className='w-100' style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className='text-center mb-4'>Try it for free</h2>
            <Card.Text className="text-center">
              No credit card required.
            </Card.Text>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Work email</Form.Label>
                <Form.Control type="email" required ref={emailRef} className="w-20" />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control type="password" required ref={passwordConfirmRef} />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Let me know via email about new features" ref={newsletterRef} />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">Sign up</Button>
            </Form>
          </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert> }
          Already have an account? <Link to="/login">Log in</Link>
        </div>
        {shouldRedirect && <div><Navigate to="/login?signup=true" /></div> }
      </div>
    </Container>
  )
}
