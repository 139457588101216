import React, { useContext, useEffect } from 'react';
import Navigation from './Components/Navigation';
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Account from './pages/Account';
import NotFound from './pages/NotFound';
import Footer from './Components/Footer';
import MailchimpCallback from './pages/MailchimpCallback';
// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from './context/AuthContext';

import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';


function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-8RDQW7LZSE");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location])

  useEffect(() => {
    context.getAuthInfo();
  }, [])

  if (context.has_been_verified === undefined) {
    return (<div>Loading...</div>);
  }

  return (
    <>
        <Navigation />
        <br /><br />
        <Routes>
          <Route path="/" element={<Home />} />
          {context.user_id && <Route path="/dashboard" element={<Dashboard />} />}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<Account />} />
          <Route path="/mailchimp-callback" element={<MailchimpCallback />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </>
  );
}

export default App;
