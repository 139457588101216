import React, { useContext } from 'react';
import { Container, Alert, Button, OverlayTrigger, Tooltip, ListGroup, Badge, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export default function Account() {
  const context = useContext(AuthContext);

  // check if a callback arrived from Stripe's checkout - not sure if needed
  // right now creates an infinite loop - doesn't stop checking
  // const [searchParams] = useSearchParams();
  // const subscribedToNewPlan = searchParams.get("subscription")
  // if (subscribedToNewPlan) context.getAuthInfo();

  const [searchParams] = useSearchParams();
  const stripeSuccess = searchParams.get('subscription');

  const setNewsletterStatus = (e) => {
    console.log("Event: ", e.target.checked);
    const request = {
      value: e.target.checked
    }
    axios.put('/api/user/update-newsletter-setting', request)
    .then(response => {
      console.log("RESPONSE: ", response.data);
    })
  }

  const setNotificationsStatus = (e) => {
    console.log("Event: ", e.target.checked);
    const request = {
      value: e.target.checked
    }
    axios.put('/api/user/update-notifications-setting', request)
    .then(response => {
      console.log("RESPONSE: ", response.data);
    })
  }

  // change buttons for updating plans based on current plan
  let planButtons;
  switch (context.plan) {
    case "Basic plan":
      planButtons =
        <>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" className='me-3'>Change plan</Button>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" className='me-3'>Billing portal</Button>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" variant="danger">Cancel plan</Button>
        </>;
      break;
    case "Pro plan":
      planButtons =
        <>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" className='me-3'>Change plan</Button>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" className='me-3'>Billing portal</Button>
          <Button href="https://billing.stripe.com/p/login/test_8wM03R2yTbTN2Xe7ss" variant="danger">Cancel plan</Button>
        </>;
        break;
    default:
      planButtons =
        <LinkContainer to="/pricing"><Button>Subscribe to paid plan</Button></LinkContainer>
    break;
  }

  return (
      <Container className='d-flex flex-column align-items-center justify-content-center'>

        <Helmet>
          <title>Account Settings - Linkchimp</title>
          <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
        </Helmet>

        <h1 className="display-4 mb-5">Account Settings</h1>

        <br /><br />
        
        <div className='w-100' style={{ maxWidth: "700px" }}>
          <h4>Personal</h4>
          <ListGroup as="ol">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">User email</div>
                {context.email}
              </div>
            </ListGroup.Item>

            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Linkchimp newsletter about product updates</div>
              </div>
              <div>
              <Form>
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  defaultChecked={context.newsletter}
                  onClick={setNewsletterStatus}
                />
              </Form>
              </div>
            </ListGroup.Item>

            <br /><br />
            <h4>Plans & billing</h4>
            {stripeSuccess
                  ?
                    <Alert variant='success'>Plan change successful. Please allow a few minutes for updating if you don't see your new plan show up here immediately.</Alert>
                  :
                    <></>
                }
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Current plan</div>
                { context.plan }
              </div>
              {planButtons}
            </ListGroup.Item>

            <br /><br />
            <h4>Mailchimp integration</h4>

            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  Integration status
                  {' '}
              <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id='mailchimp-tooltip'>
                  NOTE: An enabled integration only means that the authentication process with Mailchimp has been finished. It doesn't guarantee that the integration is currently working. Try integrating again if it's not working atm.
                </Tooltip>
              }><InfoCircle /></OverlayTrigger>
                </div>
                {context.mailchimp_prefix
                ?
                <Badge bg="success" pill className='mt-2'>Integrated</Badge>
                : 
                <Badge bg="danger" pill className='mt-2'>Not integrated</Badge>
              }
              </div>
              
            </ListGroup.Item>


            <br /><br />
            <h4>Automated reports</h4>
            {context.features.includes("notifications")
                ?
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                    >
                    <div className="ms-3 me-auto">
                      <div className="fw-bold">Automatically emailed reports</div>
                      Get reports about broken links emailed to you automatically on every 1st day of the month.
                    </div>
                    <div>
                      <Form>
                        <Form.Check 
                          type="switch"
                          id="custom-switch"
                          defaultChecked={context.notifications}
                          onClick={setNotificationsStatus}
                        />
                    </Form>
                    </div>
                  </ListGroup.Item>
                :
                  <Alert variant="warning">
                      <Alert.Heading>Premium feature</Alert.Heading>
                      <p>
                          Upgrade to receive automatic notification emails about broken links.
                      </p>
                      <div>
                        <LinkContainer to="/pricing">
                            <Button variant="success">
                                Check out plans
                            </Button>
                        </LinkContainer>
                      </div>
                  </Alert>
            }
      </ListGroup>
    </div>
    <br /><br />

      </Container>
  )
}
