import React, { useState, useContext } from 'react'
import axios from 'axios'
import { useSearchParams, Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';


function MailchimpCallback() {
    // after backend Mailchimp API calls use this state to redirect back to /dashboard
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const context = useContext(AuthContext);

    // save code from Mailchimp callback URL params
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code")
    const data = {
        code: code
    };
    
    // send Mailchimp user's temporary auth code to backend to get user's token and server prefix
    axios.post('/api/mailchimp/token', data)
        .then(res => {
            console.log("Success with message: ", res.data);
            console.log("Rerunning authentication...");
            context.getAuthInfo();
            setShouldRedirect(true);
        })

  return (
    <div>
    {shouldRedirect
        ? <div>Waiting for data from Mailchimp...</div>
        : <div><Navigate replace to="/dashboard" /></div>
        }
    </div>
  )
}

export default MailchimpCallback