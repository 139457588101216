import React, { useContext } from "react";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import StripePricingPage from "../Components/StripePricingPage";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet-async";

export default function Pricing() {
  const context = useContext(AuthContext);

  // check if ?subscription=true in the URL and initialize auth again to make sure plan is updated

  return (
    <>
      <Helmet>
        <title>Pricing - Linkchimp</title>
        <meta
          name="description"
          content="Linkchimp is a Mailchimp integration for checking broken links in emails."
        />
      </Helmet>

      <div
        className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "700px" }}
      >
        <h1 className="display-4">Pricing</h1>
        <p className="lead">Let us sniff out broken links in your emails.</p>
        <br />

        {context.user_id ? (
          <>
            <StripePricingPage />
            <div className="mt-5">
              Need to cancel your current plan? Go to{" "}
              <Link to="/account">Account Settings</Link>.
            </div>
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <Card className="mb-5" style={{ maxWidth: "300px" }}>
                  <Card.Header as="h5">Basic plan</Card.Header>
                  <Card.Body>
                    <Card.Title>5 USD / month</Card.Title>
                    <Card.Text>
                      Integrate with Mailchimp and run checks manually
                    </Card.Text>
                    <Button variant="primary" href="/register">
                      Sign up for free
                    </Button>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12 col-md-6">
                <Card className="mb-5" style={{ maxWidth: "300px" }}>
                  <Card.Header as="h5">Pro plan</Card.Header>
                  <Card.Body>
                    <Card.Title>9 USD / month</Card.Title>
                    <Card.Text>
                      Get monthly automated checks for broken links
                    </Card.Text>
                    <Button variant="primary" href="/register">
                      Sign up for free
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
