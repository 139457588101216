import React, { useRef, useContext, useState } from 'react';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Helmet } from 'react-helmet-async';

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [errorMessage, setErrorMessage] = useState(undefined);

  const context = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const signupMessage = searchParams.get('signup');

  const handleSubmit = async (data) => {
    data.preventDefault();
    console.log("Login form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value
    }
    console.log("Login API query with email: ", body.email);
    axios.post("/api/user/login", body)
      .then(res => {
        console.log("Login API response: ", res.data);
        // needs to be checked, in order to have a re-render based on context
        context.getAuthInfo();
      })
      .catch(err => {
        console.log("Login failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
      });
  }

  return (
    <Container className='d-flex align-items-center justify-content-center'>

      <Helmet>
        <title>Log in - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>

      <div className='w-100' style={{ maxWidth: "400px" }}>
      {signupMessage && <Alert variant='success'>Registration successful. Please log in.</Alert> }
        <Card>
          <Card.Body>
            <h2 className='text-center mb-4'>Log in</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" required ref={emailRef} className="w-20" />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">Log in</Button>
            </Form>
          </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert> }
          {context.user_id ? <Navigate replace to="/dashboard" /> : <div>Not registered yet? <Link to="/register">Sign up</Link></div> }
        </div>
      </div>
    </Container>
  )
}
