import React, { useState } from 'react'
import './LinkList.css';

import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';


const LinkList = (props) => {
    //console.log(props.links);

// Declare a new state variable, which we'll call "count"
    const [hidden_rows, setHiddenRows] = useState(true);


    return (
        <Table bordered hover style={{fontSize: 14}} size="sm">
        <thead>
            <tr>
                <th>Link text</th>
                <th style={{"width": "40%"}}>Link URL</th>
                <th style={{"width": "200px"}}>
                    <div style={{"display": "flex", "justifyContent": "space-between"}}>Link status <div onClick={() => { setHiddenRows(!hidden_rows) }} className="show-all-links">show all &#9662;</div></div>
                </th>
            </tr>
        </thead>
        <tbody>
            {
                props.links.map((link, i) =>
                    <tr className={hidden_rows && link.working ? 'link-hidden' : ''} key={i}>
                        <td>{link.text}</td>
                        <td><a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a></td>
                        <td>
                            {link.working ? <Alert variant="success" className='p-1'>Okay</Alert> : <Alert variant="danger" className='p-1'>Broken</Alert>}
                        </td>
                    </tr>

                )
            }
                    </tbody>
                </Table>
    )
}

export default LinkList