import React from 'react';
import { Container, Row, Col, Button, Card, Image, CardGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';

import linkchimpImage from '../email-broken-links-checker-software.png';
import meelisImage from '../meelis.jpg';
import cciImage from '../cci-logo.jpg';
import socialImage from '../linkchimp-social.jpg';


function Home() {
    return (
        <>
            <Helmet>
                <title>Linkchimp - Find broken links in Mailchimp emails</title>
                <meta name="description" key="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
                <meta
                    property="og:image"
                    key="og:image"
                    content={socialImage}
                />  
                <meta
                    property="og:title"
                    key="og:title"
                    content="Linkchimp - Find broken links in Mailchimp emails"
                />
                <meta
                    property="og:description"
                    key="og:description"
                    content="Linkchimp is a Mailchimp integration for checking broken links in emails."
                /> 
            </Helmet>

            <Container style={{"padding": "3% 3%"}}>
                <Row style={{"margin": "0% 3%", "marginBottom": "5%"}}>
                    <Col xs={{ span: 12, order: 2 }}  lg={{ span: 5, order: 1 }}>
                        <h4 className="display-5 mb-5">Are the links in your automated Mailchimp emails still working?</h4>
                        <h4>Use Linkchimp to find broken links and get automatic alerts.</h4>
                        <br />
                        <LinkContainer to="/register"><Button size="lg">Try for free</Button></LinkContainer>
                        <br /><br />
                        <p>No credit card required.</p>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }}  lg={{ span:7, order: 2 }} style={{"paddingBottom": "5%"}}>
                        <img
                            src={linkchimpImage}
                            alt="Logo"
                            className='img-fluid'
                        />
                    </Col>
                </Row>
                <br /><br />
                <hr />
                <Row style={{"padding": "10% 5% 7% 5%"}}>
                    <Col xs={{ span: 12, order: 2}}  lg={{ span: 3, order: 1}} style={{"marginBottom": "5%"}} className="text-center">
                        <Image
                            src={meelisImage}
                            alt="Meelis Ojasild"
                            roundedCircle={true}
                            style={{"maxWidth": "150px"}}
                        />
                    </Col>
                    <Col xs={{ span: 12, order: 1}}  lg={{ span: 9, order: 2}}>
                        <Card className="border-0">
                        <blockquote className="blockquote">
                            <em>“I did email marketing for over ten years at companies like Amazon and Pipedrive. I'd often find out randomly that a link somewhere in an important flow was broken for several months. It's why I created Linkchimp - to save me both the embarassement and traffic.”</em>
                        </blockquote>
                        <p>
                            - Meelis Ojasild, Founder of Linkchimp
                        </p>
                        </Card>
                    </Col>
                </Row>
                <br /><br />
                <hr />
                <Row style={{"padding": "10% 5% 7% 5%"}}>
                    <Col xs={{ span: 12, order: 2}}  lg={{ span: 9, order: 1}}>
                        <Card className="border-0">
                        <blockquote className="blockquote">
                            <em>“Linkchimp is great. It helped us find broken links we didn't know about. While we were fixing links in Mailchimp to point to the new cloud documents, we also found some links that have been broken for years. It's surprising Mailchimp doesn't offer anything like this.”</em>
                        </blockquote>
                        <p>
                            - Chris Kite, <a href="https://www.laundrycard.com" target={'_blank'}>Card Concepts Inc.</a>
                        </p>
                        </Card>
                    </Col>
                    <Col xs={{ span: 12, order: 1}}  lg={{ span: 3, order: 2}} style={{"marginBottom": "5%", "marginTop": "2%"}} className="text-center">
                        <Image
                            src={cciImage}
                            alt="Card Concepts Inc"
                            style={{"maxWidth": "150px"}}
                        />
                    </Col>
                </Row>

               
                <hr />

                <h1 className='text-center' style={{"marginBottom": "7%", "marginTop": "10%"}}>Benefits</h1>

                <CardGroup style={{"marginBottom": "13%"}}>
                    <Card className="border-0">
                        <Card.Body>
                        <Card.Title>Don't lose traffic because of broken links</Card.Title>
                        <Card.Text>
                        Linkchimp scans through all your emails (newsletters, automated flows etc.) and shows you which links are broken.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0">
                        <Card.Body>
                        <Card.Title>Piece of mind with automatic alerts</Card.Title>
                        <Card.Text>
                        Receive automated emails with broken links reports. Discover bad links when they appear not two months later.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>

                <hr />

                <div className='text-center' style={{"margin": "0% 3%"}}>
                    <h1 className='text-center' style={{"paddingTop": "15%"}}>Find out how many broken links you have</h1>
                    <h4 className='text-center' style={{"padding": "2% 0%", "fontWeight": "normal"}}>Get started in under 60 seconds without a credit card</h4>
                    <LinkContainer to="/register"><Button size="lg">Try for free</Button></LinkContainer>
                </div>


    <br /><br /><br /><br />



            </Container>
        </>
    )
}

export default Home;