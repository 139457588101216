import React from 'react'
import { Helmet } from 'react-helmet-async';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>

      <div>Page not found.</div>
    </>
    
  )
}
