import axios from "axios";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import { ListGroup, Badge, Button, Spinner, Card, Alert, Placeholder } from "react-bootstrap";
import LinkList from './LinkList';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import AuthContext from '../context/AuthContext';

export default class CampaignList extends React.Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.getMailchimpData = this.getMailchimpData.bind(this);

    }

    state = {
        campaigns: [],
        total_links_checked: 0,
        total_links_checked_okay: 0,
        total_links_checked_broken: 0,
        check_links_button_status: true,
        mailchimp_error: false
    }


    // check if the user is already authenticated and save prefix to a state


    // start OAuth 2 flow for integrating with Mailchimp
    startMailchimpAuth() {
        axios.get('/api/mailchimp/authorize')
            .then(res => {
                console.log("Mailchimp res back to frontend: ", res);
                window.location = res.data.redirect_uri;
            })
    }    


    // get campaign data from the user's Mailchimp account
    getMailchimpData() {
        this.setState({ loading: true }, () => {
            axios.get('/api/mailchimp/campaigns')
                .then(res => {
                    console.log("Got content back from Mailchimp :)", res);
                    // ideally I'd pass these through the child element back to parent
                    let links_checked = 0;
                    let links_checked_okay = 0;
                    let links_checked_broken = 0;

                    res.data.forEach(campaign => {
                        links_checked = links_checked + campaign.links.length;
                        campaign.links.forEach(link => {
                            if (link.working) {
                                links_checked_okay++;
                            } else {
                                links_checked_broken++;
                            }
                        })
                    });

                    this.setState({ 
                        loading: false,
                        campaigns: res.data,
                        total_links_checked: links_checked,
                        total_links_checked_okay: links_checked_okay,
                        total_links_checked_broken: links_checked_broken
                     });
                })
                .catch(err => {
                    console.log("Error with requesting data from Mailchimp: ", err);
                    this.setState(
                        {
                            mailchimp_error: err.message,
                            loading: false
                        }
                    )
                })
            })
    }

    render() {
        console.log("CONTEXT IN CampaignList.js: ", this.context);
        const { loading } = this.state;
        return (
            <>

                <div style={{"maxWidth": "700px"}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>1. Integrate with Mailchimp</Card.Title>
                            <Card.Text>
                                You need to have at least <a href="https://mailchimp.com/help/manage-user-levels-in-your-account/" target="_blank" rel="noopener noreferrer">Manager level access</a> in Mailchimp to integrate.
                            </Card.Text>
                            <Card.Text>
                                You can integrate with only one Mailchimp account at a time. If you want to integrate with another Mailchimp account, you will lose the previous integration.
                            </Card.Text>
                            <Button variant="primary" onClick={this.startMailchimpAuth}>Integrate with Mailchimp</Button>
                            {this.context.mailchimp_prefix
                                ?
                                <Alert variant="success" className="mt-3">
                                    Successfully integrated with Mailchimp.
                                </Alert>
                                :
                                <Alert variant="danger" className="mt-3">
                                    Not integrated with Mailchimp yet.
                                </Alert>
                            }

                        </Card.Body>
                    </Card>
                    <Card className="mt-5">
                        <Card.Body>
                            <Card.Title>2. Check for broken links</Card.Title>
                            <Card.Text>
                                Linkchimp will check all your Mailchimp emails (both automations and campaigns) that are either drafts, queued, or still sending (but not fully sent out yet).
                            </Card.Text>
                            <Button variant="primary" onClick={this.getMailchimpData} disabled={!this.context.mailchimp_prefix}>Check for broken links</Button>
                            {this.state.mailchimp_error &&
                                <Alert variant="danger" className="mt-3">
                                    Getting Mailchimp emails failed with the message: {this.state.mailchimp_error}.
                                    <br /><br />
                                    Please refresh the page and try again.
                                </Alert>
                            }
                            <br /><br />
                            {loading
                            ? <><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> This might take a while...</>
                            : <div style={{"maxWidth": "700px"}}>
                                <ListGroup>
                                    <ListGroup.Item key="email_checked">Emails checked <Badge bg="primary" pill>{this.state.campaigns.length}</Badge></ListGroup.Item>
                                    <ListGroup.Item key="total_links_checked">Total links checked <Badge bg="primary" pill>{this.state.total_links_checked}</Badge></ListGroup.Item>
                                    <ListGroup.Item key="working_links">Working links <Badge bg="success" pill>{this.state.total_links_checked_okay}</Badge></ListGroup.Item>
                                    <ListGroup.Item key="broken_links">Broken links <Badge bg="danger" pill>{this.state.total_links_checked_broken}</Badge></ListGroup.Item>
                                </ListGroup>
                            </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
                

                <div className="mt-5 mb-5" style={{"maxWidth": "700px"}}>

                <Card className="mt-5">
                    <Card.Body>
                        {this.context.features.includes("links")
                            ?
                                <></>
                            :
                                <Alert variant="warning">
                                    <Alert.Heading>Premium feature</Alert.Heading>
                                    <p>
                                        Upgrade to our paid plans to see the list of broken links along with email info.
                                    </p>
                                    <div>
                                        <LinkContainer to="/pricing">
                                            <Button variant="success">
                                                Check out plans
                                            </Button>
                                        </LinkContainer>
                                    </div>
                                </Alert>
                        }

                        <Card.Title>3. Fix the broken links</Card.Title>

                            <Card.Text>All the links (along with email info) will appear below after they have been checked.</Card.Text>

                            {this.context.features.includes("links")
                                ?
                                    <></>
                                :
                                    <Placeholder as="p" animation="wave">
                                        <Placeholder xs={12} />
                                        <Placeholder xs={10} />
                                        <Placeholder xs={12} />
                                        <br />
                                        <br />
                                        <Placeholder xs={10} />
                                        <Placeholder xs={10} />
                                        <Placeholder xs={3} />
                                        <br />
                                        <br />
                                        <Placeholder xs={8} />
                                        <Placeholder xs={12} />
                                        <Placeholder xs={10} />
                                        <br />
                                        <br />
                                        <Placeholder xs={10} />
                                        <Placeholder xs={12} />
                                        <Placeholder xs={12} />
                                        <br />
                                        <br />
                                        <Placeholder xs={8} />
                                        <Placeholder xs={12} />
                                        <Placeholder xs={10} />     
                                    </Placeholder>
                            }


                    </Card.Body>
                </Card>

                      
                    {this.state.campaigns.map((campaign, i) => (
                        this.context.features.includes("links")
                            ? (
                            // if the user is on the pro plan, show the links
                            <div key={i}><br />
                                <h5>
                                    {campaign.title}{ ' ' }
                                    <sup>
                                        <a href={`https://${this.context.mailchimp_prefix}.admin.mailchimp.com/campaigns/show/?id=${campaign.web_id}`} target="_blank" rel="noopener noreferrer" style={{ "fontWeight": "200", "textDecoration": "none" }}><BoxArrowUpRight /></a>
                                    </sup>
                                </h5>
                                <LinkList links={campaign.links} />
                            </div>
                            ) : 
                            // if the user is not on the pro plan, don't show the links 
                            <></>
                        )
                    )}

                </div>
            </>
        )
    }
}

CampaignList.contextType = AuthContext;