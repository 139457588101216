import React from 'react'
// this is needed to make Bootstrap links react the same way as <Link> would in React Router
import Logo from '../linkchimp-100x103.png';



function Footer (props) {
  return (
    <>
        <div className='text-center p-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', marginTop: "5%" }}>
            © {new Date().getFullYear()} Linkchimp
            {' '}
            <img
                src={Logo}
                alt="Linkchimp logo"
                style={{height: "20px"}}
            />
        </div>
  </>
  )
}

export default Footer