import React from 'react'
import { Helmet } from 'react-helmet-async';
import { Image, Row, Col } from 'react-bootstrap';
import { Linkedin, Twitter, Medium } from 'react-bootstrap-icons';
import meelisImage from '../meelis.jpg';
import Logo from '../linkchimp-100x103.png';

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>


      <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" style={{"maxWidth": "700px", "marginBottom": "10%"}}>
        <h1 className="display-4">Get in touch</h1>

        <Row>
          <Col style={{"marginTop": "15%"}}>
          <h4>The Company</h4>
          <br />
          <img
              src={Logo}
              alt="Logo"
              className='img-fluid'
              style={{"maxWidth": "100px"}}
          />
          <br /><br />
          <strong>Mink OÜ</strong><br />
          <a href="mailto:info@linkchimp.net?subject=Feedback from Linkchimp.net Contact page">info@linkchimp.net</a><br />
          Company reg number: 12258838<br /><br />

          <h6>Juridical address</h6>
          Mink OÜ<br />
          Õle 24-5<br />
          Tallinn, Estonia<br />
          44312
          </Col>
          <Col style={{"marginTop": "15%"}}>
          <h4>The People</h4>
          <br />
          <Image
              src={meelisImage}
              alt="Meelis Ojasild"
              roundedCircle={true}
              style={{"maxWidth": "100px"}}
          />
          <br /><br />
          <strong>Meelis Ojasild</strong><br />
          CEO, Founder of Linkchimp
          <br /><br />
          <a href="https://www.linkedin.com/in/meelisojasild/"><Linkedin style={{"fontSize": "1.5rem"}} /></a>{' '}<a href="https://twitter.com/meelis_o"><Twitter style={{"fontSize": "1.5rem"}} /></a>{' '}<a href="https://medium.com/@meelis-ojasild"><Medium style={{"fontSize": "1.5rem"}} /></a>
          
          </Col>
        </Row>



      </div>
    </>
  )
}
