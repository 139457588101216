import React, { useContext } from 'react'
import { Container, Nav, Navbar, NavDropdown, Badge } from 'react-bootstrap';
import { BoxArrowInRight } from 'react-bootstrap-icons';
// this is needed to make Bootstrap links react the same way as <Link> would in React Router
import { LinkContainer } from 'react-router-bootstrap'
import AuthContext from '../context/AuthContext';
import Logo from '../linkchimp-100x103.png';



function Navigation (props) {
  // console.log("CONNNN NAV: ", this.context);
  const context = useContext(AuthContext);
  console.log("Navigation got the context: ", context);

  let planBadge;

  switch (context.plan) {
    case "Free plan":
      planBadge = <Badge bg="danger" text="dark">{context.plan}</Badge>;
      break;
    case "Basic plan":
      planBadge = <Badge bg="warning" text="dark">{context.plan}</Badge>;
      break;
    case "Pro plan":
      planBadge = <Badge bg="success" text="dark">{context.plan}</Badge>;
      break;
    default:
      break;
  }

  

  return (
    <>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand>
              <img
                src={Logo}
                height="30"
                className="d-inline-block align-top"
                alt="Linkchimp logo"
              />
        </Navbar.Brand>
        <LinkContainer to="/"><Navbar.Brand>Linkchimp</Navbar.Brand></LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/"><Nav.Link href="/">Home</Nav.Link></LinkContainer>
            {context.user_id && <LinkContainer to="/dashboard"><Nav.Link href="/dashboard">Dashboard</Nav.Link></LinkContainer>}
            <LinkContainer to="/pricing"><Nav.Link href="/pricing">Pricing</Nav.Link></LinkContainer>
            <LinkContainer to="/contact"><Nav.Link href="/contact">Contact</Nav.Link></LinkContainer>
          </Nav>
          <Nav> 
          {context.user_id
              ? <>
                  <Nav>
                    <LinkContainer to="/account">
                      <Nav.Link href="/account">
                        {planBadge}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav>
                  <Nav>
                    <NavDropdown title="Settings">
                      <LinkContainer to="/account"><NavDropdown.Item href="/account">Account</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/pricing"><NavDropdown.Item href="/pricing">Change plan</NavDropdown.Item></LinkContainer>
                      <NavDropdown.Divider />
                      <LinkContainer to="/logout">
                        <NavDropdown.Item>Log out <BoxArrowInRight /></NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </Nav>
                </>
            :
              <Nav>
                <LinkContainer to="/register"><Nav.Link href="/register">Sign up</Nav.Link></LinkContainer>
                <LinkContainer to="/login"><Nav.Link href="/login">Login</Nav.Link></LinkContainer>
              </Nav>
            }
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
  )
}

export default Navigation