import React from 'react';
import Container from 'react-bootstrap/Container';
import CampaignList from '../Components/CampaignList';
import { Helmet } from 'react-helmet-async';

export default function Dashboard() {
  return (
    <Container className='d-flex flex-column align-items-center justify-content-center'>

      <Helmet>
        <title>Dashboard - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>

      <div>
      <div className="" style={{"maxWidth": "700px"}}>
        <h1 className="display-4 mb-5">Dashboard</h1>
        <br />
      </div>
        <CampaignList />
        </div>
    </Container>
  )
}
