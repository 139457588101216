import React, { useContext, useState } from 'react';
import { Container, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Helmet } from 'react-helmet-async';

export default function Login() {

  const [errorMessage, setErrorMessage] = useState(undefined);

  const context = useContext(AuthContext);


  const logOut = async (data) => {
    console.log("Logout request submitted.");
    axios.get("/api/user/logout", {withCredentials: true})
      .then(res => {
        console.log("Logout API response: ", res.data)
        // needs to be checked, in order to have a re-render based on context
        context.getAuthInfo();
      })
      .catch(err => {
        console.log("Logout failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
      });
  }
    logOut();

  return (
    <Container className='d-flex align-items-center justify-content-center' style={{ minheight: "100vh" }}>

      <Helmet>
        <title>Log out - Linkchimp</title>
        <meta name="description" content="Linkchimp is a Mailchimp integration for checking broken links in emails." />
      </Helmet>


      <div className='w-100' style={{ maxWidth: "400px" }}>
        <div className='w-100 text-center mt-2'>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert> }
          {context.user_id ? <div>Logging you out... please wait...</div> : <Navigate replace to="/" /> }
        </div>
      </div>
    </Container>
  )
}
